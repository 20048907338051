var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.componentType,_vm._g(_vm._b({tag:"component",staticClass:"c-button tw-text-center tw-transition-colors tw-select-none",class:[
    _vm.variant,
    _vm.variantClasses,
    _vm.sizeClasses,
    _vm.linkClasses,
    _vm.positionClasses,
    _vm.roundnessClasses,
    {
      'tw-flex tw-items-center tw-justify-center tw-w-full tw-text-center tw-truncate': _vm.block,
      'tw-inline-flex tw-items-center tw-justify-center tw-max-w-full': !_vm.block,
      'outline-button': _vm.outline
    }
  ],attrs:{"to":_vm.to,"href":_vm.href,"role":_vm.role,"disabled":_vm.disabled || _vm.loading,"target":_vm.isLink ? _vm.target : null,"type":_vm.isLink ? null : _vm.type,"title":_vm.title,"data-attribute":_vm.attributeValue}},'component',_vm.$attrs,false),Object.assign({}, _vm.$listeners, { click: _vm.handleClick })),[(_vm.$slots.icon || _vm.icon)?_c('span',{staticClass:"tw-relative tw-flex tw-items-center tw-min-w-0",class:[
      _vm.size === 'sm' ? 'tw-gap-1' : 'tw-gap-2',
      { 'tw-flex-row-reverse': _vm.iconPosition === 'right' }
    ]},[_c('span',{staticClass:"tw-inline-flex tw-items-center tw-relative",class:[_vm.iconSize.class, _vm.iconContainerClasses]},[(_vm.loading)?_c('CSpinner',{staticClass:"tw-flex",class:_vm.spinnerClasses,attrs:{"size-class":_vm.spinnerSize.size,"border-class":_vm.spinnerSize.border,"variant":_vm.spinnerVariant}}):(_vm.$slots.icon)?_vm._t("icon"):_c('CDynamicIcon',{class:{ 'tw-invisible': _vm.loading },attrs:{"icon":_vm.icon,"size":_vm.iconSize}})],2),_vm._v(" "),(_vm.$slots.default)?_c('span',{class:[_vm.slotClasses, { 'tw-truncate tw-max-w-full': !_vm.block }]},[_vm._t("default")],2):_vm._e()]):[(_vm.loading)?_c('span',{staticClass:"tw-absolute tw-top-1/2 tw-left-1/2 tw--translate-x-1/2 tw--translate-y-1/2"},[_c('CSpinner',{staticClass:"tw-flex",attrs:{"variant":_vm.spinnerVariant,"size-class":_vm.spinnerSize.size,"border-class":_vm.spinnerSize.border}})],1):_vm._e(),_vm._v(" "),_c('span',{class:[
        _vm.containerClasses,
        { 'tw-invisible': _vm.loading, 'tw-truncate tw-max-w-full': !_vm.block }
      ]},[_vm._t("default")],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }