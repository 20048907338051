























import {
  computed,
  defineNuxtComponent,
  ref,
  vue3Model,
  watch
} from '~/utils/nuxt3-migration'
import { ciExternalLink } from '~/icons/source/regular/external-link'
import { useI18n } from '~/compositions/i18n'

export default defineNuxtComponent({
  model: vue3Model,
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    displayUrl: {
      type: String,
      default: null
    },
    target: {
      type: String,
      default: '_self'
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n()

    const visible = ref(props.modelValue)

    watch(
      () => props.modelValue,
      newValue => {
        visible.value = newValue
      }
    )
    watch(visible, newValue => {
      emit('update:modelValue', newValue)
    })

    const urlIsPlot = computed(() => {
      const { hostname } = new URL(props.url)
      return hostname === 'plot.gr' || hostname === 'www.plot.gr'
    })
    const youAreBeingRedirectedMessage = computed(() => {
      if (urlIsPlot.value) {
        return t("you're being redirected to plot.gr")
          .toString()
          .replace('plot.gr', 'Plot.gr')
      }
      return t("you're about to exit car.gr")
        .toString()
        .replace('car.gr', 'Car.gr')
    })

    function close() {
      visible.value = false
    }

    return {
      ciExternalLink,
      visible,
      urlIsPlot,
      youAreBeingRedirectedMessage,
      close
    }
  }
})
