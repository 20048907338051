export const DECREASE_MESSAGES_COUNT = 'DECREASE_MESSAGES_COUNT'
export const SET_CONFIG = 'SET_CONFIG'
export const SET_CATEGORY_ID = 'SET_CATEGORY_ID'
export const SET_BASE_CATEGORY = 'SET_BASE_CATEGORY'
export const SET_CATEGORY_IDS = 'SET_CATEGORY_IDS'
export const SET_SCHEMA_DATA = 'SET_SCHEMA_DATA'
export const SET_SCHEMA_EXTRAS = 'SET_SCHEMA_EXTRAS'
export const SET_FIELD_VALUES_DATA = 'SET_FIELD_VALUES_DATA'
export const SET_ALL_CATEGORIES = 'SET_ALL_CATEGORIES'
export const SET_FIELD_ERRORS_DATA = 'SET_FIELD_ERRORS_DATA'
export const SET_FIELD_VALUES = 'SET_FIELD_VALUES'
export const SET_FIELD_VALUE = 'SET_FIELD_VALUE'
export const CLEAR_ERROR = 'CLEAR_ERROR'
export const SET_ROOT_CATEGORY_ID = 'SET_ROOT_CATEGORY_ID'
export const SET_LOADING = 'SET_LOADING'
export const SET_SCHEMA_LOADING = 'SET_SCHEMA_LOADING'
export const SET_CLASSIFIED_ID = 'SET_CLASSIFIED_ID'
export const SET_PAGE = 'SET_PAGE'
export const SET_EDIT_MODE = 'SET_EDIT_MODE'
export const SET_SHOW_FLOATING_SAVE_BUTTON = 'SET_SHOW_FLOATING_SAVE_BUTTON'
export const SET_TOP_ALERT = 'SET_TOP_ALERT'
export const SET_FIELD_SUGGESTIONS = 'SET_FIELD_SUGGESTIONS'
export const ADD_TO_HISTORY = 'ADD_TO_HISTORY'
export const ADD_CLIENT_VALIDATOR = 'ADD_CLIENT_VALIDATOR'
export const CLEAR_CLIENT_VALIDATORS = 'CLEAR_CLIENT_VALIDATORS'
export const SET_PAGE_KEY = 'SET_PAGE_KEY'
export const SET_INVALID_TRIGGERED = 'SET_INVALID_TRIGGERED'
export const SET_PENDING_DESCRIPTION = 'SET_PENDING_DESCRIPTION'
export const RESET_STATE = 'RESET_STATE'
export const SET_USER_TELEPHONES = 'SET_USER_TELEPHONES'
export const SET_REQUIRES_PAYMENT = 'SET_REQUIRES_PAYMENT'
export const SET_TERMS_ACCEPTED = 'SET_TERMS_ACCEPTED'
export const SET_WARNING_CONFIRMATION_MESSAGE =
  'SET_WARNING_CONFIRMATION_MESSAGE'
export const SET_IS_WARNING_CONFIRMATION_VISIBLE =
  'SET_IS_WARNING_CONFIRMATION_VISIBLE'
