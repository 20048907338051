import { CompactPage } from '~/models/common/types'
import {
  AlternativeSearch,
  SearchTitlePart,
  Category,
  Facet,
  MassActions,
  Pagination,
  SearchLink,
  SearchSellerRow,
  SellerClassifieds,
  SellersInfo,
  SortField,
  Stats,
  TextSearch,
  ClassifiedSearchResultRow
} from '~/models/search/types'

import { ViewType } from '~/models/shared/types'
import { DEFAULT_NUMBER_OF_RESULTS_PER_PAGE } from '~/constants/search/defaults'
import { AdsTargeting, BackendAdsConfig } from '~/models/ads'
import { PaidClassifiedsInfoSummary } from '~/models/classified/paid/types'
import { SearchConfig } from '~/models/search/config'
import { VARIANT_SEARCH_PARAM } from '~/constants/makemodels'

export const CLASSIFIED_SEARCH_NS = 'classifieds/search'
export const SHOW_SELLERS_PARAM = 'show-sellers'

export interface SearchRoute {
  path: string
  query: Record<string, any>
}

export interface SearchState {
  isQuickSearch: boolean
  config: SearchConfig
  params: Record<string, any> & { pg?: number }
  newWantedClassifiedUrl?: string
  showInPlotUrl?: string
  perPage: number
  args: (Record<string, any> & { category: string[] }) | {}
  category: Category | undefined
  categoryIds: number[] // Contains ancestor and own ids
  facets: Facet[]
  rows: ClassifiedSearchResultRow[] | null
  clusterRows: ClassifiedSearchResultRow[] | null
  sellerRows: SearchSellerRow[] | null
  pagination: Pagination
  clusterPagination: Pagination
  shareable: boolean
  savable: boolean
  loading: {
    results: boolean
    facets: boolean
    map: boolean
    clusterResults: boolean
  }
  actionsAreCollapsed: boolean
  isFavorite?: boolean
  notifications: {
    instantValid?: boolean
    bulkValid?: boolean
  }
  previousSearch: { name: string; search: string; time: number } | null
  viewType: ViewType
  adsTargetings?: AdsTargeting[]
  canBeSaved?: boolean
  changeSearchUrl?: string
  longTitle?: SearchTitlePart[]
  massActions: MassActions
  mySearchLinks?: SearchLink[]
  userProfile?: object | null
  newClassifiedUrl?: string
  mapSearchUrl?: string
  searchId?: string | null
  shortTitle?: SearchTitlePart[]
  sortFields?: SortField[]
  stats?: Stats
  title?: string
  textSearch?: TextSearch
  page?: CompactPage
  showMobileFacets: boolean
  sellersClassifiedsInfo?: SellerClassifieds
  sellersInfo?: SellersInfo
  alternativeSearches?: AlternativeSearch[]
  paidSummary?: PaidClassifiedsInfoSummary
  // TODO: type these
  map: {
    clusters: any
    markers: any
  }
  flags: {
    isSellersOnlySearch: boolean
    isMapSearch: boolean
  }
  // We are forced to keep the route state here since we can not consistently use the $route property in search
  // we can probably remove these when we migrate to vue-router 4 (vue 3)
  currentRoute: SearchRoute
  previousRoute: SearchRoute
  userOwnsSearch: boolean
  backendAdsConfig: BackendAdsConfig
  notMyClassifiedsUrl?: string
}
export const NO_LOADING_FACETS = Object.freeze([
  'shop-q',
  'shop-s',
  VARIANT_SEARCH_PARAM
])
function getDefaultState(): SearchState {
  return {
    isQuickSearch: false,
    category: undefined,
    categoryIds: [],
    config: {
      settings: {}
    },
    params: {},
    perPage: DEFAULT_NUMBER_OF_RESULTS_PER_PAGE,
    args: {},
    facets: [],
    rows: null,
    clusterRows: null,
    sellerRows: null,
    savable: false,
    shareable: false,
    searchId: null,
    massActions: {},
    shortTitle: [],
    sortFields: [],
    previousSearch: null,
    loading: {
      facets: true,
      results: true,
      map: true,
      clusterResults: false
    },
    actionsAreCollapsed: false,
    viewType: ViewType.LIST,
    canBeSaved: false,
    pagination: {
      page: 1,
      pageParam: '',
      perPage: DEFAULT_NUMBER_OF_RESULTS_PER_PAGE,
      total: 0
    },
    textSearch: undefined,
    clusterPagination: { page: 1, pageParam: '', perPage: 24, total: 0 },
    page: {
      breadcrumbs: [],
      metadata: [],
      seoUrl: '',
      title: '',
      externalUrl: '',
      targetSeoUrl: ''
    },
    userProfile: null,
    isFavorite: undefined,
    notifications: {
      bulkValid: undefined,
      instantValid: undefined
    },
    showMobileFacets: false,
    sellersClassifiedsInfo: undefined,
    sellersInfo: undefined,
    alternativeSearches: [],
    paidSummary: undefined,
    stats: undefined,
    mapSearchUrl: undefined,
    currentRoute: {
      query: {},
      path: ''
    },
    previousRoute: {
      query: {},
      path: ''
    },
    flags: {
      isSellersOnlySearch: false,
      isMapSearch: false
    },
    map: {
      clusters: [],
      markers: []
    },
    userOwnsSearch: false,
    backendAdsConfig: {
      disabled: false
    }
  }
}

export default getDefaultState
