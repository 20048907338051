import { containerScoped } from '~/decorators/dependency-container'
import { setUrlParam } from '~/utils/http'
import { inject } from 'tsyringe'
import { CategoryId } from '~/models/category/types'
import { StoreWithRootState } from '~/store/types'
import { USER_NS } from '~/store/modules/shared/user/state'
import { VueRouter, VueI18n } from '~/utils/nuxt3-migration'
import { capitalizeAllWords } from '~/utils/string'
import { ciAudit } from '~/icons/source/regular/audit'
import DealerSiteService from '~/services/dealers/site/DealerSiteService'
import { ciPlus } from '~/icons/source/regular/plus'
import {
  storeToken,
  vueI18nToken,
  vueRouterToken
} from '~/constants/dependency-injection/tokens'
import { ciUser } from '~/icons/source/solid/user'

@containerScoped()
export class LegacyUrlService {
  constructor(
    @inject(storeToken) private store: StoreWithRootState,
    @inject(vueRouterToken) private router: VueRouter,
    @inject(vueI18nToken) private i18n: VueI18n,
    @inject(DealerSiteService) private dealerSiteService: DealerSiteService
  ) {}

  public getRegisterUrl(params?: any) {
    let url = this.router.resolve({ name: '__guest_register' }).href

    const registerReferenceCode =
      this.store.getters[`${USER_NS}/referenceCode`] ||
      (this.store.state.classifieds?.edit?.registerReferenceCode as string)
    if (registerReferenceCode) {
      url = setUrlParam(url, 'refcode', registerReferenceCode)
    }

    for (const prop in params) {
      url = setUrlParam(url, prop, params[prop])
    }

    if (this.dealerSiteService.routeIsOfDealerSite()) {
      url = `https://car.gr${url}`
    }

    return url
  }

  isAdmin(): boolean {
    return this.store.getters[`${USER_NS}/isAdmin`]
  }

  isDealer(): boolean {
    return this.store.getters[`${USER_NS}/isOfTypeDealer`]
  }

  isPayingUser(): boolean {
    return this.store.getters[`${USER_NS}/isPayingUser`]
  }

  isAuditsUser(): boolean {
    return this.store.getters[`${USER_NS}/isAuditsUser`]
  }

  public offerBtnLink() {
    if (this.isAdmin()) {
      return '/admin/users/search/?user-type=manager&pg=1'
    } else if (this.isAuditsUser()) {
      return this.router.resolve({
        name: '__account_audits_external_certificates'
      }).href
    }
    return this.getNewClassifiedUrl()
  }

  public offerBtnIcon() {
    if (this.isAdmin()) {
      return ciUser
    } else if (this.isAuditsUser()) {
      return ciAudit
    }
    return ciPlus
  }

  public offerBtnText(oneWord: boolean = false) {
    if (this.isAdmin()) {
      return this.i18n.t('users') as string
    } else if (this.isDealer() || this.isPayingUser()) {
      return oneWord
        ? (this.i18n.t('offer') as string)
        : (this.i18n.t('new classified') as string)
    } else if (this.isAuditsUser()) {
      return oneWord
        ? (this.i18n.t('audits::elegxoi') as string)
        : (this.i18n.t('audits::texnikoi') as string)
    }

    if (oneWord) {
      return this.i18n.t('offer') as string
    }
    return capitalizeAllWords(this.i18n.t('free classified') as string)
  }

  public getNewClassifiedUrl() {
    return '/classifieds/new/?type=new'
  }

  public getUserLoginUrl() {
    return '/login/'
  }

  public getUserLogoutUrl() {
    return '/logout/'
  }

  public getBuyCreditsUrl() {
    return '/account/credits/'
  }

  getCompatibilityCopyUrl(categoryIds: number[], classifiedId: number): string {
    const categoryId = categoryIds[categoryIds.length - 1]
    if (categoryIds.includes(CategoryId.JOBS)) {
      return `/jobs/new/${categoryId}/?copy=${classifiedId}`
    } else if (categoryIds.includes(CategoryId.RENTALS)) {
      return `/rentals/new/${categoryId}/?copy=${classifiedId}`
    } else if (categoryIds.includes(CategoryId.WANTED_PARTS)) {
      return `/wanted-parts/new/${categoryId}/?copy=${classifiedId}`
    }
    return `/classifieds/${classifiedId}/legacy-copy/`
  }
}
